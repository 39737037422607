@import "shared/styles/core";

.figure {
  margin: ($spacing * 10) 0;
}

.image {
  width: 100%;
  border: $default-border-width solid var(--core-black);
}

.caption {
  margin: $spacing 0;
  font-size: $font-size-slide-caption;
  line-height: 1.5;
  color: var(--color-slide-caption);
}

@include media-breakpoint-up(lg) {
  .figure {
    margin: 0;
    margin-left: -($spacing * 24);
  }

  .caption {
    padding-left: ($spacing * 24);
  }
}
