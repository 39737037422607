@import "shared/styles/core";

.blockQuote {
  position: relative;
  margin: ($spacing * 10) 0;
  font-size: var(--font-size-h1-sm);
  font-family: var(--font-family-body-bold);
}

.icon {
  display: block;
  margin-right: $spacing;
  margin-bottom: ($spacing * 3);
  color: var(--link-on-surface);
}

.author {
  color: var(--link-on-surface);
  margin-top: ($spacing * 3);
}

.position {
  font-family: var(--font-family-body);
  color: var(--content-tertiary);
}

@include media-breakpoint-up(lg) {
  .blockQuote {
    font-size: var(--font-size-h1);
  }

  .icon {
    position: absolute;
    top: $spacing;
    left: -($spacing * 15);
    color: var(--link-on-surface);
    margin-bottom: 0;
  }

  .author {
    margin-top: ($spacing * 6);
  }
}
