@import "shared/styles/core";

$counter-height: 34px;
$counter-width-sm: 24px;
$counter-width: 40px;

.ul,
.ol {
  margin: ($spacing * 6) 0;
  padding: 0;
  list-style: none;
}

.ul {
  > li {
    margin-bottom: ($spacing * 6);

    &::before {
      display: inline-flex;
      height: $spacing;
      min-width: ($spacing * 4);
      margin-top: ($spacing * 3);
      margin-right: ($spacing * 6);
      background-color: var(--content-primary);
      content: "";
    }
  }
}

.ol {
  counter-reset: ol-counter;

  > li {
    counter-increment: ol-counter;
    margin-bottom: ($spacing * 6);

    &::before {
      font-family: var(--font-family-sections-heading);
      font-size: var(--font-size-h3);
      font-weight: var(--font-weight-sections-heading);
      display: inline-flex;
      min-width: $counter-width-sm;
      padding-right: $spacing;
      color: var(--content-primary);
      content: counter(ol-counter);
      line-height: $counter-height;
    }
  }
}

@include media-breakpoint-up(lg) {
  .ul,
  .ol {
    margin: ($spacing * 8) 0;
  }

  .ol {
    > li {
      &::before {
        font-size: var(--font-size-h3);
        min-width: $counter-width;
      }
    }
  }
}
