@import "shared/styles/core";

.wrapper {
  display: flex;
  color: var(--foundation-on-primary);
}

.heading {
  font-family: var(--font-family-body-bold);
  margin-right: ($spacing * 3);
}

.starWrapper {
  margin: 0;
  line-height: 1;
  cursor: pointer;

  &:not(:last-child) {
    padding-right: ($spacing * 2);
  }
}

.star {
  &.selected {
    path {
      fill: var(--foundation-on-primary);
    }
  }
}

.remove {
  margin-left: ($spacing * 2);
  color: var(--foundation-on-primary);
  border: none;
  height: auto;

  &:hover {
    color: var(--foundation-on-primary);
  }

  &:active {
    color: var(--foundation-on-primary);
  }
}

.embeded {
  &.wrapper {
    color: var(--link-on-surface);
  }

  .star {
    &.selected {
      path {
        fill: var(--link-on-surface);
      }
    }
  }
}
