@import "shared/styles/core";

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: $layer-2;
  padding-top: ($spacing * 10);

  &:not(.embeded)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ($spacing * 10);
    background-color: var(--surface-secondary);
    z-index: $layer-2;
  }
}

.wrapper {
  z-index: $layer-3;
}

.avatar {
  margin-top: -($spacing * 8);
  margin-bottom: ($spacing * 2);
  z-index: $layer-2;
  width: 100%;
}

.heading {
  color: inherit;
  z-index: $layer-2;
  margin-bottom: ($spacing * 6);
}

.link {
  color: var(--core-white);
  margin: auto;
  padding: 0 ($spacing);
}

.embeded {
  padding: 0;

  .avatar {
    margin-top: 0;
  }

  .heading {
    margin-bottom: ($spacing * 2);
  }
}

@include media-breakpoint-up(lg) {
  .header {
    flex-direction: row;
    padding-top: 0;

    &::before {
      display: none;
    }
  }

  .avatar {
    margin-top: 0;
    margin-right: ($spacing * 8);
    margin-bottom: 0;
  }

  .heading {
    margin-bottom: 0;
  }

  .link {
    margin-left: 0;
  }

  .embeded {
    .heading {
      margin-bottom: 0;
    }
  }
}
