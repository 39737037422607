@import "shared/styles/core";

.embed {
  margin: 0;
}

.embededArtist {
  text-decoration: none;
  color: var(--content-primary);
}

@include media-breakpoint-up(lg) {
  .embed:not(.aligned) {
    margin: 0;
    margin-left: -($spacing * 24) !important;
  }
}
