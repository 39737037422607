@import "shared/styles/core";

$artwork-size: 175px;
$rate-height: 50px;

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: $layer-2;
  padding-top: ($spacing * 10);

  &:not(.embeded)::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ($spacing * 10);
    background-color: var(--surface-secondary);
    z-index: $layer-2;
  }
}

.artwork {
  flex: 0 0 $artwork-size;
  margin: -($spacing * 8) 0 ($spacing * 2);
  width: $artwork-size;
  height: $artwork-size;
  z-index: $layer-2;
  position: relative;
}

.info {
  z-index: $layer-2;
}

.heading {
  color: var(--foundation-on-primary);
  margin-bottom: ($spacing * 4);
  text-align: center;
}

.name {
  text-decoration: none;
}

.artistName {
  font-size: var(--font-size-button);
  font-family: var(--font-family-secondary);
  font-style: var(--font-weight-secondary);
  font-weight: var(--font-weight-sections-heading);
  text-transform: uppercase;
}

.rates {
  display: flex;
  flex-direction: column;
  color: var(--foundation-on-primary);
  margin-bottom: ($spacing * 8);
  z-index: $layer-2;

  .rate {
    display: flex;
    align-items: center;
    height: $rate-height;
    border: $default-border-width solid var(--foundation-on-primary);

    &:last-child {
      border-top: none;
    }

    &:first-child {
      border-top: $default-border-width solid var(--foundation-on-primary);
    }
  }

  .score {
    width: $rate-height;
    line-height: $rate-height;
    text-align: center;
    border-right: $default-border-width solid var(--foundation-on-primary);
  }

  .label {
    padding: 0 ($spacing * 3);
  }
}

.rating {
  margin-bottom: ($spacing * 7);
}

.link {
  text-decoration: none;
}

.embeded {
  padding: 0;

  .heading,
  .rates {
    color: var(--content-primary);
    border-color: var(--content-primary);
  }

  .artwork {
    margin-top: 0;
    border: $default-border-width solid var(--core-black);
  }

  .rates {
    .rate,
    .rate:first-child,
    .score {
      border-color: var(--content-primary);
    }
  }
}

@include media-breakpoint-up(lg) {
  .header {
    flex-direction: row;
    padding-top: 0;

    &::before {
      display: none;
    }
  }

  .artwork {
    margin: 0 ($spacing * 8) 0 0;
  }

  .heading {
    margin-bottom: ($spacing * 2);
    text-align: left;
  }

  .rates {
    flex-direction: row;
    border: $default-border-width solid var(--foundation-on-primary);
    border-left: none;
    margin-bottom: 0;

    .rate {
      border: none;

      &:first-child {
        border-top: none;
      }
    }

    .score {
      border-left: $default-border-width solid var(--foundation-on-primary);
    }
  }
}
