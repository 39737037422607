@import "shared/styles/core";

.heading {
  margin: ($spacing * 8) 0 ($spacing * 3);
}

@include media-breakpoint-up(lg) {
  .heading {
    margin: ($spacing * 10) 0 ($spacing * 4);
  }
}
